// @ts-ignore
import Tinker from "./index.ts";

/**
 *  @param params
 */
 export const getApiOuth = () => new Tinker({
    url:'/api/v1/system/apiOuth',
    header: { method: 'GET' },
    apiLock:true,
    dataType: 'json',
});
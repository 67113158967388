import { getUserinfo } from '@/service/service.ts'
function is(auth) {
    const ua = window.navigator.userAgent.toLowerCase();
    if(auth==='wechat'){
        return !!ua.match(/MicroMessenger/i)
    }else if(auth==='alipay'){
        return !!ua.match(/AlipayClient/i)
    }else if(auth==='android'){
        return !!ua.match(/Android/i)
    }else if(auth==='iPhone'){
        return !!ua.match(/iPhone/i)
    }else if(auth==='iosIpad'){
        return !!ua.match(/iPhone ios-ipad/i)
    }
};
function handleStringToArr_length(string, step) {
    const r = [];
    for (let i = 0, len = string.length; i < len; i += step) {
        r.push(string.substring(i, i + step))
    }
    return r;
  }
//当前时间转化为年月日
function dataToDate(){
  const today = new Date();
  const year = today.getFullYear();
  // 月份+1，因为从0开始计算，并且确保两位数
  const month = ('0' + (today.getMonth() + 1)).slice(-2);
  // 日期确保两位数
  const day = ('0' + today.getDate()).slice(-2);
  return `${year}${month}${day}`;
}  
//时间转化为分秒
function dataToMinite(dateTimeStr){
    const date = new Date(dateTimeStr);
     // 获取当前日期（今日）的Date对象
    const today = new Date();
    //判断是否为昨天的日期
    const yesterday=new Date(today)
    yesterday.setDate(yesterday.getDate() - 1)
    // 提取指定日期的年 月 日 小时、分钟和秒
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let seconds = date.getSeconds();
    hours = hours < 10 ? '0' + hours : hours 
    minutes = minutes < 10 ? '0' + minutes : minutes
    seconds = seconds < 10 ? '0' + seconds : seconds
    month = month < 10 ? '0' + month : month;
    day = day < 10 ? '0' + day : day; // 日期不足两位数时前面补零

    const formattedTime ={
        month:`${month}-${day}`,
        hours:`${hours}:${minutes}:${seconds}`,
        min:`${hours}:${minutes}`,
        isToday:today.getFullYear() === year &&
        today.getMonth() === date.getMonth() && // 判断是否为今天
        today.getDate() === date.getDate(),
        isYesterday:date.getDate() === yesterday.getDate() && // 比较日
        date.getMonth() === yesterday.getMonth() && // 比较月
        date.getFullYear() === yesterday.getFullYear() // 比较年
    }
    return formattedTime
}

//获取user方法
 function getUser(bol){
    let user=sessionStorage.getItem('userInfo')
    if(!bol || bol==undefined && !user){
        if(!user){
            getUserinfo().success(res => {
                sessionStorage.setItem('userInfo', JSON.stringify(res.data.datas))
            }).start().catch(err => {})
        }
    }else{
        getUserinfo().success(res => {
            sessionStorage.setItem('userInfo', JSON.stringify(res.data.datas))
        }).start().catch(err => { })
    }
}

export  {is,handleStringToArr_length,dataToDate,dataToMinite,getUser};
import loadable from '@loadable/component'
const StrategyList = loadable(() => import("../pages/strategyList/StrategyList.page.jsx"));
const StrategyDetail = loadable(() => import("../pages/strategyDetail/StrategyDetail.page.jsx"));
// const NotFound = loadable(() => import("../pages/notFound/NotFound.page"));
const EtfDetail = loadable(() => import("../pages/etfDetail/EtfDetail.page.jsx"));
const Article = loadable(() => import("../pages/article/article.page"));
const ArticleDetail = loadable(() => import("../pages/article/articleDetail.page"));
const Annoucement = loadable(() => import("../pages/annoucement/Annoucement.page"));
const AnnouceDetail = loadable(() => import("../pages/annouceDetail/AnnouceDetail.page"))
const Clear = loadable(() => import("../pages/clear/clear.page"))
interface RouterArrResult {
    defaultRouter: string, //默认显示的路由
    router: Array<{
        name?:string,
        path:string,
        component: any,
        cacheWhen?: "always" | "forward" | "back",
        cacheKey?:string,
        pointerArt?:string
    }>
}

export const routerArr = [
            {
                name:'首页',
                path:'/',
                component: StrategyList,
                cacheWhen: 'forward',
                cacheKey: 'StrategyList',
            },
            {
                name:'策略详情页',
                path:'/StrategyDetail',
                component: StrategyDetail,
                cacheWhen: 'forward',
                cacheKey: 'StrategyDetail',
            },
            {
                name:'ETF详情页',
                path:'/EtfDetail',
                component: EtfDetail,
                cacheWhen: 'forward',
                cacheKey: 'EtfDetail',
            },
           
                {
                    name:'文章列表页',
                    path:'/Article',
                    component: Article,
                    cacheWhen: 'forward',
                    cacheKey: 'Article',
                },
                {
                    name:'文章列表页',
                    path:'/ArticleDetail',
                    component: ArticleDetail,
                },
                {
                    name:'公告列表页',
                    path:'/Annoucement',
                    component: Annoucement,
                },
                {
                    name:'公告列表详情页',
                    path:'/AnnouceDetail',
                    component: AnnouceDetail,
                },
                {
                    name:'清理token调试页面',
                    path:'/clear',
                    component: Clear,
                }
        ]

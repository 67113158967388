export const clearCache_config:Array<{cacheKey:string,clearPath:Array<string>}> = [
    {
        cacheKey:'EtfDetail',
        clearPath:[ '/etfDetail'],
    },
    {
        cacheKey:'StrategyDetail',
        clearPath:[ '/strategyDetail'],
    },
    {
        cacheKey:'Article',
        clearPath:[ '/Article'],
    },
];
import * as CryptoJS from 'crypto-js';  

/*AES加密  key: AES密钥   to : 向量*/
export function AESEncrypt(data , key, to) {
    let dataStr = data // CryptoJS.enc.Utf8.parse( JSON.stringify(data));
    if(Array.isArray(data)){
        dataStr = JSON.stringify(data)
    }
    let encrypted = CryptoJS.AES.encrypt(dataStr, CryptoJS.enc.Latin1.parse(key), {
        // iv: CryptoJS.enc.Latin1.parse(to),
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.toString();
}

/*AES解密 key: AES密钥   to : 向量*/
export function AESDecrypt(data, key , to) {
    let data2 = data.replace(/\n/gm, "");
    // {mode:CryptoJS.mode.ECB,padding: CryptoJS.pad.Pkcs7}
    let decrypted = CryptoJS.AES.decrypt(data2, CryptoJS.enc.Latin1.parse(key), {
        // iv: CryptoJS.enc.Latin1.parse(to),
        mode: CryptoJS.mode.ECB, //根据后台设定算法模式 CBC ECB
        padding: CryptoJS.pad.Pkcs7
    });
    return decrypted.toString(CryptoJS.enc.Utf8);
}

// Base64加密 encrypt
export function  Base64Decrypt_encrypt(params) {
    let wordArray = CryptoJS.enc.Utf8.parse(params);
    let base64 = CryptoJS.enc.Base64.stringify(wordArray);
    return base64
}

// Base64加密 decrypt
export function  Base64Decrypt_decrypt(params) {
    let parsedWordArray = CryptoJS.enc.Base64.parse(params);
    let parsedStr = parsedWordArray.toString(CryptoJS.enc.Utf8);
    return parsedStr
}


import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App.jsx";
//redux
import { Provider } from "react-redux";
import store from "./store/index.js";
import { is, handleStringToArr_length } from "./utils/utils.js";
import { AESEncrypt } from "./utils/crypto.js";
import { getApiOuth } from "./service/apiLock.ts";
import { hex_md5 } from "./utils/md5.ts";
import auth from "./utils/wechartAuth.js";
require("@/service/index.ts");
if (process.env.NODE_ENV !== "production" && process.env.NODE_ENV !== "test") {
  renderApp();
} else {
  //进入页面不拉授权
  if (is('wechat') && window.location.href.substring(window.location.href.toString().length - 10)=='state=auth') {
      auth().then(()=>{
        renderApp();
      }).catch(err => {
        renderApp();
      });
  }else{
    renderApp();
  }
}
// auth()
function getApiLock() {
  return new Promise((reslove, reject) => {
    getApiOuth().success((res) => {
        const timer = new Date().getTime();
        sessionStorage.setItem("userToken", res.data.datas);
        const ApiList = handleStringToArr_length(res.data.datas, 8);
        const AESHttpData = AESEncrypt(ApiList[timer % 10], "vGEZCiIXRIImAWSv");
        store.dispatch({
          type: "GET_APILOCK_SUCESS",
          value: {
            key: hex_md5(timer + AESHttpData),
            timer: timer,
          },
        });
        reslove("ok");
      })
      .start();
  });
}
export async function renderApp() {
  await getApiLock();
  ReactDOM.render(
        <div
          className="relative font-mi text-black bg-neutral-200 dark:bg-neutral-900 dark:text-white
          antialiased select-none overflow-hidden h-[101vh] "
        >
          <div className="h-screen overscroll-contain overflow-auto" id='content'>
            <Provider store={store}>
                <App />
            </Provider>
          </div>
        </div>,
        document.getElementById("root")
  );
}
/**
 * 该文件主要是判断微信是否登录
*/
import {is} from './utils.js'
import * as qs from 'qs';
import { fetchWXLogin,getUserinfo } from '../service/service.ts';
let APPID = 'wx10feaa7977ba1b45'//线上的记得改
if (process.env.NODE_ENV == 'test' || process.env.NODE_ENV=='development') {
    APPID = 'wxb73ad1be0915aa62';
}
//这个是线上的
//let APPID = 'wx10feaa7977ba1b45'
// if (process.env.REACT_APP_ENV == 'test' || process.env.NODE_ENV=='development') {
//let APPID = 'wxb73ad1be0915aa62';

const SCOPE = 'snsapi_userinfo';
export default function auth() {
        const REDIRECT_URI = encodeURIComponent(window.location.href);
        return new Promise((resovle, reject) => {
            const token = localStorage.getItem('token');
            const query = qs.parse(window.location.search, { ignoreQueryPrefix: true });
            const { code, inviteCode, clear ,type } = query;
            let coes
            if(Array.isArray(code)){
                coes=code[1]
            }else{
                coes=code
            }
            if (clear) {
                localStorage.removeItem('token');
                reject();
            }
            //如果为开发环境  
            //eyJhbGciOiJIUzI1NiJ9.eyJqdGkiOiIyOTQyRSIsInN1YiI6IntcImxhc3RMb2dpblRpbWVcIjoxNzExNjEwMDA5NTE2LFwiaWRcIjoxMDAwMDAwNDZ9IiwiaWF0IjoxNzExNjEwMDA5LCJleHAiOjIwMjY5NzAwMDl9.ELdu-HI5zE2dIPXxPMdWTVpf-sQn9QFzUro2n9Al9Qg
            //测试环境 eyJhbGciOiJIUzI1NiJ9.eyJqdGkiOiIyOTQyRSIsInN1YiI6IntcImxhc3RMb2dpblRpbWVcIjoxNzEyNjI5MTk1MTMwLFwiaWRcIjoxMDAwMDI4Njl9IiwiaWF0IjoxNzEyNjI5MTk1LCJleHAiOjIwMjc5ODkxOTV9.L0JCPb0Bd1vKlxQ9-4XobLk0Pb9YIEHulRULGkKql8I
            if(process.env.NODE_ENV == 'development'){
                localStorage.setItem('token', 'eyJhbGciOiJIUzI1NiJ9.eyJqdGkiOiIyOTQyRSIsInN1YiI6IntcImxhc3RMb2dpblRpbWVcIjoxNzExNjEwMDA5NTE2LFwiaWRcIjoxMDAwMDAwNDZ9IiwiaWF0IjoxNzExNjEwMDA5LCJleHAiOjIwMjY5NzAwMDl9.ELdu-HI5zE2dIPXxPMdWTVpf-sQn9QFzUro2n9Al9Qg');
                resovle()
                return;
            }
            const arrayAllow=[]
            if(arrayAllow.some(element => element.includes(window.location.pathname))){
                resovle();
            }else{
                if (is('wechat')) {
                    if (window.location.href.substring(window.location.href.toString().length - 10)=='state=auth') {
                        code && fetchWXLogin({
                            code:coes,
                            inviteCode,
                            type
                        }).success(res => {
                            try {
                                const { token } = res.data.datas;
                                if (token) {
                                    localStorage.setItem('token', token);
                                    resovle();
                                }else{
                                    localStorage.setItem('token', null);
                                    resovle();
                                }
                            } catch (e) {
                                window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${APPID}&redirect_uri=${REDIRECT_URI}&response_type=code&scope=${SCOPE}&state=auth#wechat_redirect`;
                                reject();
                            }
                        }).start();
                    } else {
                        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${APPID}&redirect_uri=${REDIRECT_URI}&response_type=code&scope=${SCOPE}&state=auth#wechat_redirect`;
                    }
                } else {
                    window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${APPID}&redirect_uri=${REDIRECT_URI}&response_type=code&scope=${SCOPE}&state=auth#wechat_redirect`;
                }
                
            }
    
        });
    }

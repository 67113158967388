import {routes,history} from "@/router/index.jsx";
import React, { useEffect,useRef,useCallback, useState } from "react";
import store from "@/store/index.js";

function App() {
  useEffect(()=>{
    clearBrowseHistory()
    downBrowseHistory(location)
    let unhistory= history.listen(route => {
      //保存用户浏览路径
      downBrowseHistory(route)
    })
    return () => {
        unhistory=null
    };
  },[])

  function clearBrowseHistory() {
    store.dispatch({
        type: 'change_lastRouter_pathName',
        value: []
    })
  }
  function downBrowseHistory(route) {
    let storeRouteData = store.getState().routeLast_pathName;
    let routeHistory = [...storeRouteData];
    if (history.action == 'PUSH') {
            routeHistory.push(route.pathname)
    } else if (history.action == 'POP') {
            if (routeHistory && routeHistory.length > 0) {
                    routeHistory.pop()
            } else {
                    routeHistory.push(route.pathname)
            }
    } else {
            if (routeHistory && routeHistory.length > 0) {
                    routeHistory[routeHistory.length - 1] = route.pathname
            } else {
                    routeHistory.push(route.pathname)
            }
    }
    store.dispatch({
            type: 'change_lastRouter_pathName',
            value: routeHistory.slice(-10)
    })
}

  return (
    <div className="App">
        {routes}
    </div>
  );
}

export default App;

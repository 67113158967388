const defaultState = {
    APILOCK: window.localStorage.getItem('APILOCK') ? JSON.parse(window.localStorage.getItem('APILOCK')) : '',
    routeLast_pathName: window.localStorage.getItem('lastRouter_pathName')? JSON.parse(window.localStorage.getItem('lastRouter_pathName')):[],
};

const reduler= (state = defaultState, action) =>{
    if (action.type === 'GET_APILOCK_SUCESS') {
        const newState = {...state};
        if (action.value) {
            window.localStorage.setItem('APILOCK',action.value ? JSON.stringify(action.value):'')
        }else{
            window.localStorage.removeItem('APILOCK')
        }
        newState.APILOCK = action.value
        return newState
    }
    if(action.type === 'GET_TIME_SHARE'){
        const newState = {...state};
        newState.TIMESHARE = action.value
        return newState
    }
    if(action.type === 'GET_ETFTIME_SHARE'){
        const newState = {...state};
        newState.ETFTIMESHARE = action.value
        return newState 
    }
    if(action.type=='GET_USERINFOS_SUCESS'){
        const newState = {...state};
        newState.USERINFOS = action.value
        return newState
    }
    if(action.type=='GET_PART325_LIST'){
        const newState = {...state};
        newState.GET_PART325_LIST = action.value
        return newState
    }
    if(action.type=='SET_STATE'){
        const newState = {...state};
        newState.SET_STATE = action.value
        return newState
    }
    if(action.type === 'change_lastRouter_pathName'){
        const newState = {...state};
        window.localStorage.setItem('lastRouter_pathName',JSON.stringify(action.value));
        newState.routeLast_pathName = action.value
        return newState
    };
    return state
}
export default reduler
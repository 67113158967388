import * as qs from "qs";
import { is, handleStringToArr_length } from "../utils/utils";
import { AESEncrypt } from "../utils/crypto.js";
// @ts-ignore
import { hex_md5 } from "../utils/md5.ts";
import  auth  from "../utils/wechartAuth.js"
import store from "../store";
import { Dialog,Toast } from 'antd-mobile'
require('whatwg-fetch')
export interface TinkerParams {
  url: string;
  header?: { method: "GET" | "POST" };
  data?: any;
  apiLock?: boolean;
  dataType?: "json" | string;
  ContentType?: "multipart/form-data;";
}

export interface Result {
  data?: any;
  statusCode?: number;
  header?: { [key: string]: any };
}

//这个声明貌似也没用
declare function resultCallBack(result: Result): boolean;
class Tinker {
  static engine: (params: TinkerParams) => Promise<Result>;
  static getHeader: (params: TinkerParams) => { [key: string]: any };
  static isSuccess: (params: Result) => boolean;

  private engine: {};
  private params: TinkerParams;
  successCalBack: (result: Result) => void;
  failCallBack: (result: Result) => void;

  constructor(params: TinkerParams) {
    if (!params.dataType) {
      params.dataType = "json";
    }
    this.engine = Tinker.engine;
    this.params = params;
    this.successCalBack = () => {};
    this.failCallBack = () => {};
  }

  public async start() {
    const result: Result = await Tinker.engine(this.params);
    if (result?.data?.code === "-002") {
      const msg = result.data.message;
      // alertV2(msg)
    }
    //OSS 上传图片 statusCode == 200  即为成功 否则 失败
    if (this.params.ContentType == "multipart/form-data;") {
      let statusCode = result.statusCode;
      if (statusCode == 200) {
        this.successCalBack && this.successCalBack(result);
        return;
      } else {
        throw result;
        return;
      }
    }
    
    
    if (Tinker.isSuccess(result)) {
      this.successCalBack && this.successCalBack(result);
    } else {
      if(result &&result.data && result.data.code=='-006'){
        Dialog.alert({
          content:result.data.message,
          closeOnMaskClick: true
        })
        throw result;
      }else if(result &&result.data &&(result.data.code == "-005")){
        localStorage.removeItem('token');
        sessionStorage.setItem('getUrl',window.location.href)
        auth();
      }else if(result &&result.data &&(result.data.code == "-004" || result.data.code == "-001" || result.data.code == "-003")){

      }else{
        if(result &&result.data){
          //result.data.message
          Toast.show({
            content: result.data.message,
            position: 'center',
          })
        }       
        throw result;
      }

      if (result) {
        this.failCallBack && this.failCallBack(result);
      }
      if (result.statusCode !== 200 || !result.data || (result.data && result.data.code !== "000")) {
        throw result;
      }
    }
  }

  public success(successCalBack: (result: Result) => void) {
    this.successCalBack = successCalBack;
    return this;
  }

  public failure(failCallBack: (result: Result) => void) {
    this.failCallBack = failCallBack;
    return this;
  }
}

Tinker.engine = async function (params: TinkerParams) {
  let arrWhiteList=['/api/v1/user/userInfo','/api/v1/system/systemConfig','/api/v1/strategy/info']
  let url = params.url;
  
  var substringBeforeChar;
  let urlChart=url.indexOf('?')
  if(urlChart!==-1){
    substringBeforeChar=url.substring(0,urlChart)
  }else{
    substringBeforeChar=url
  }
  let result: any = null;
  const search = qs.parse(window.location.search, { ignoreQueryPrefix: true });
 
  if (params.header && params.header.method === "GET") {
    if (search._source && arrWhiteList.includes(substringBeforeChar)) {
      params.data._source = search._source;
    }
    if (params.data) {
      url = `${url}?${qs.stringify(params.data)}`;
    }
    result = await window.fetch(url, {
      headers: {
        ...Tinker.getHeader(params),
      },
    });
  } else if (params.header && params.header.method === "POST") {
    if (search._source && arrWhiteList.includes(substringBeforeChar)) {
      url = `${url}?_source=` + search._source;
    }
    result = await window.fetch(url, {
      headers: {
        ...Tinker.getHeader(params),
      },
      method: "POST",
      body: params.ContentType ? params.data : JSON.stringify(params.data),
    });
  }
  let data;
  try {
    if (params.dataType === "json") {
      data = await result.json();
    } else {
      data = await result.text();
    }
  } catch (e) {
    //Sentry.captureException(e);
  }
  return {
    statusCode: result.status,
    data,
  };
};

Tinker.isSuccess = (result: Result) => {
  return (
    result &&
    result.statusCode === 200 &&
    result.data &&
    (result.data.code === "000" ||
      result.data.code === "043" ||
      result.data.code === "HB403" ||
      result.data.code === "035" ||
      result.data.code === "014" ||
      result.data.code === "025" ||
      result.data.code === "034" ||
      result.data.code === "500")
  );
};
Tinker.getHeader = (params) => {
  const url = params.url;
  let apiLockParams = {};
  if (params.apiLock === undefined) {
    const timer = new Date().getTime();
    const userToken = sessionStorage.getItem("userToken");
    const ApiList = handleStringToArr_length(userToken, 8);
    const AESHttpData = AESEncrypt(ApiList[timer % 10], "vGEZCiIXRIImAWSv");
    const key = hex_md5(timer + AESHttpData);
    const apiLockInfo = store.getState().APILOCK;
    apiLockParams = {
      zstokv1: apiLockInfo ? key : "",
      timeStamp: apiLockInfo ? timer : "",
    };
  }
  let obj;
  if(localStorage.getItem("token")!=null && localStorage.getItem("token")!='null'){
    obj={
      token: localStorage.getItem("token"),
      requestFrom:'wechat',
      "Content-Type": "application/json;charset=utf-8",
      Expires: -1,
      "COURSE-SOFT-TOKEN": "COURSE",
      ...(params.apiLock ? null : apiLockParams)
     }
  }
  if(localStorage.getItem("token")==null || localStorage.getItem("token")=='null'){
    obj={
      requestFrom:'wechat',
      "Content-Type": "application/json;charset=utf-8",
      Expires: -1,
      "COURSE-SOFT-TOKEN": "COURSE",
      ...(params.apiLock ? null : apiLockParams)
     }
  }
  
  return obj;
};

export default Tinker;

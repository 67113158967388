import * as React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import * as qs from 'qs';
import { CacheRoute, CacheSwitch, dropByCacheKey, getCachingKeys } from 'react-router-cache-route'
import { createBrowserHistory } from 'history';
import { clearCache_config } from './routerCacheClear.ts';
import { routerArr } from './router.ts';
export const history = createBrowserHistory();

let pathName = history.location.pathname;
const pushFn = history.push
history.push = function(path)  {
  pushFn(path,pathName)
}
history.listen((route, action) => {
        //卸载缓存
        let clearConfig = clearCache_config;
        window.sessionStorage.setItem('wechatShare_Url', window.location.href)
        clearConfig.forEach((item, index) => {
            let cacheArr = getCachingKeys(); //获取已缓存的页面的 cacheKey
            if (cacheArr.length > 0 && cacheArr.includes(item.cacheKey) && action == 'PUSH') {
                dropByCacheKey(item.cacheKey)
            }
        })
})
export const routes = (
    <Router history={history}>
        <CacheSwitch>
            {
                routerArr.map((item, index) => {
                   return item.cacheWhen ? (
                        <CacheRoute
                            key={index}
                            path={item.path}
                            component={item.component}
                            when={item.cacheWhen}
                            cacheKey={item.cacheKey ? item.cacheKey : null}
                            exact={true}
                        />
                   ) : (
                        <Route path={item.path} component={item.component} key={index} exact={true} />
                   )
                })
            }
        </CacheSwitch>
    </Router>
);

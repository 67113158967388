// @ts-ignore
import Tinker from "./index.ts";

// 主营构成
export const getAllBanner = (params) =>
  new Tinker({
    url: "/api/v2/banner/query",
    header: { method: "GET" },
    dataType: "json",
    data: params,
  });
//微信登录
export const fetchWXLogin = (params: {
  code: string;
  inviteCode?: string;
  type?: string;
}) =>
  new Tinker({
    url: `/api/v1/wechat/outh`,
    header: { method: "GET" },
    data: params,
    apiLock:true
  });

  /**
 * 当前登录用户信息
 */
export const getUserinfo = (params?:{}) => {
  return (
      new Tinker({
          url: `/api/v1/user/userInfo`,
          header: { method: 'GET' },
          dataType: 'json',
          data: { ...params},
          apiLock:true
      })
  )
};
 /**
 * 首页策略列表
 * params:{
 *  page:number
 * }
 */
export const stragegyList = (params?:{page:number}) =>
new Tinker({
  url: "/api/v1/strategy/infos",
  header: { method: "GET" },
  dataType: "json",
  data: {...params},
});
 /**
 * 首页我的策略订阅列表
 * params:{
 *  page:number
 * }
 */
 export const subscribeList = (params?:{page:number}) =>
  new Tinker({
    url: "/api/v1/strategy/subInfos",
    header: { method: "GET" },
    dataType: "json",
    data: {...params},
  });

//  首页新接口
export const newSubscribeList = () =>
  new Tinker({
    url: "/api/v1/strategy/listv2",
    header: { method: "GET" },
    dataType: "json"
  });
/**
 * 微信验证配置
 * @param {*} params
 */
export const getWXconfig = (params: { url: string }) => new Tinker({
  url: `/api/v1/wechat/shareSign`,
  header: { method: 'GET' },
  data: params,
});
/**
 * 获取系统配置
 * @param {*} params
 */
export const systemConfig = (params: {name?:string}) => new Tinker({
  url: `/api/v1/system/systemConfig`,
  header: { method: 'GET' },
  data: params,
});
/**
 * 策略文章列表
 * @param {*} params
 */
export const articles = () => new Tinker({
  url: `/api/v1/strategy/articles`,
  header: { method: 'GET' },
  dataType: "json",
});
/**
 * 策略文章详情页
 * @param {*} params
 */
export const article = (params: { id: number }) => new Tinker({
  url: `/api/v1/strategy/article`,
  header: { method: 'GET' },
  data: params
});

/**
 * 策略信息
 * @param {*} params
 */
export const info = (params: { code: string }) => new Tinker({
  url: `/api/v1/strategy/info`,
  header: { method: 'GET' },
  data: params
});
/**
 * 分时 5日
 * @param {*} params
 */
export const ktime = (params: { codes: string,day:number }) => new Tinker({
  url: `/api/v1/strategy/ktime`,
  header: { method: 'GET' },
  data: params
});

/**
 * 1 3 6 1年 2年
 * @param {*} params
 */
export const kday = (params: { codes: string }) => new Tinker({
  url: `/api/v1/strategy/kday`,
  header: { method: 'GET' },
  data: params
});

/**
 * 最新持仓
 * @param {*} params
 */
export const holdings = (params: { code: string }) => new Tinker({
  url: `/api/v1/strategy/holdings`,
  header: { method: 'GET' },
  data: params
});

/**
 * 持仓记录
 * @param {*} params
 */
export const record = (params: { code: string }) => new Tinker({
  url: `/api/v1/strategy/holdingsRecord`,
  header: { method: 'GET' },
  data: params
});

/**
 * etf详情
 * @param {*} params
 */
export const details = (params: { etfCode: string }) => new Tinker({
  url: `/api/v1/etf/details`,
  header: { method: 'GET' },
  data: params
});
/**
 * 通过策略查询商品信心
 * @param {*} params
 */
export const goodsInfo = (params: { codes: string }) => new Tinker({
  url: `/api/v1/strategy/goodsInfo`,
  header: { method: 'GET' },
  data: params
});

/**
 * 用户订阅信息
 * @param {*} params
 */
export const userSubInfo = (params: { code: string }) => new Tinker({
  url: `/api/v1/strategy/userSubInfo`,
  header: { method: 'GET' },
  data: params
});

/**
 * 微信支付
 * @param {*} params
 */
export const wxPay = (params: { goodId: number, num?: number, amount?: number, couponDetailId?: number, sceneId?: number }) => {

  return new Tinker({
      url: `/api/v2/wx/pay`,
      header: { method: 'GET' },
      dataType: 'json',
      data: params,
  })
}
/**
 * 预约订阅
 * @param {*} params
 */
export const reserve = (params: { code: string}) => {
  return new Tinker({
      url: `/api/v1/strategy/reserve`,
      header: { method: 'GET' },
      dataType: 'json',
      data: params,
  })
}
/**
 *  获取 分时数据http
 *  @param params
 */
export const getKtimeData = (params?: { code: string, days: number }) => new Tinker({
  url: 'api/v2/k/subscribeShare',
  header: { method: 'GET' },
  dataType: 'json',
  data: params,
});
/**
 *  获取 新-日K数据http
 *  @param params
 */
export const newgetdayK = (params?: { code: string , isCn: boolean }) => new Tinker({
  url: 'api/v2/k/dayKV2',
  header: { method: 'GET' },
  dataType: 'json',
  data: params,
});
/**
 *  获取 实时价格Http
 *  @param params
 */
export const getKrealTime = (params?: { codes: string, isCN: any }) => new Tinker({
  url: 'api/v2/k/subscribeRealTime',
  header: { method: 'GET' },
  dataType: 'json',
  data: params,
});

/**
 *  查看最新调仓
 *  @param params
 */
export const readTransfer = (params?: { code: string }) => new Tinker({
  url: 'api/v1/strategy/readTransfer',
  header: { method: 'GET' },
  dataType: 'json',
  data: params,
});
/**
 *  活动策略头部
 *  @param params
 */
export const infoActivity = (params: {}) => new Tinker({
  url: 'api/v1/activity/info',
  header: { method: 'GET' },
  dataType: 'json',
  data: params,
});
/**
 *  活动策略列表
 *  @param params
 */
export const strategys = (params: {}) => new Tinker({
  url: 'api/v1/activity/strategys',
  header: { method: 'GET' },
  dataType: 'json',
  data: params,
});
/**
 *  策略公告文章列表
 *  @param params
 */
export const articeList = (params?: {pageNum:number,pageSize:number,classify:string,returnContent: boolean,hasTop:boolean}) => new Tinker({
  url: 'api/v1/article/list',
  header: { method: 'GET' },
  dataType: 'json',
  data: params,
});
//  获取比对code
export const scode = () => new Tinker({
  url: 'api/v1/strategy/compareIndex',
  header: { method: 'GET' },
  dataType: 'json',
});
/**
 *  获取文章详情页
 *  @param params
 */
export const getV2 = (params?: { id: string }) => new Tinker({
  url: 'api/v2/article/getV2',
  header: { method: 'GET' },
  dataType: 'json',
  data: params,
});

/**
 *  评论列表
 *  @param params
 */
export const commentList = (params?: { targetType: string, targetId :number}) => new Tinker({
  url: 'api/v1/article/comment/list',
  header: { method: 'GET' },
  dataType: 'json',
  data: params,
});

/**
 *  发布评论
 *  @param params
 */
export const publishComment = (params?: { targetType: string, targetId :number,parentCommentId:any,contentText:string,replyUserId:number}) => new Tinker({
  url: 'api/v1/article/comment/comment',
  header: { method: 'POST' },
  dataType: 'json',
  data: params,
});
/**
 *  置顶评论
 *  @param params
 */
export const topComment = (params?: { commentId : number, top :number}) => new Tinker({
  url: 'api/v1/article/comment/top',
  header: { method: 'GET' },
  dataType: 'json',
  data: params,
});
//隐藏评论
export const delComment = (params?: { commentId : number, status :number}) => new Tinker({
  url: 'api/v1/article/comment/hide',
  header: { method: 'GET' },
  dataType: 'json',
  data: params,
});
//开关开启关闭
export const PublishTransferNotice = (params?: { code : string, onoff :number}) => new Tinker({
  url: 'api/v1/strategy/transferNotice',
  header: { method: 'GET' },
  dataType: 'json',
  data: params,
});
